import cn from 'clsx'

const BugReportModal = ({ handleSubmit, register, errors, user }) => {
  return (
    <dialog className='modal' id='bug-report-modal'>
      <form className='modal-box' onSubmit={handleSubmit}>
        <textarea
          placeholder='Tell us what happened'
          className={cn(
            'textarea textarea-bordered my-2 h-24 w-full ',
            errors?.message && 'border-error placeholder-error'
          )}
          {...register('message', {
            required: true,
          })}
        />
        {!user?.id && (
          <div className='flex flex-col'>
            <input
              type='email'
              placeholder='Email address '
              className={cn(
                'textarea textarea-bordered my-2 w-full',
                errors?.email && 'border-error placeholder-error'
              )}
              {...register('email', {
                required: true,
              })}
            />
            <input
              type='tel'
              pattern='[0-9]{10}'
              placeholder='Phone number'
              className={cn(
                'textarea textarea-bordered my-2 w-full',
                errors?.phone && 'border-error placeholder-error'
              )}
              {...register('phone', {
                required: false,
              })}
            />
          </div>
        )}
        <button type="submit'" className='btn btn-primary btn-md w-full'>
          Submit
        </button>
      </form>
      <form method='dialog' className='modal-backdrop'>
        <button>close</button>
      </form>
    </dialog>
  )
}

export default BugReportModal
